import Vue from 'vue'
import VueRouter from 'vue-router'
import syctemMapValue from "lodash_.mapvaluess";

Vue.use(VueRouter)
    //
let routes = syctemMapValue.system_route_require(true);

const router = new VueRouter({
    mode: 'hash',
    routes
})

router.beforeEach((to, from, next) => {
    // 统一设置标题
    document.title = to.meta.title;
    let loginStatus = localStorage.getItem('loginStatus');
    if (!to.meta.auth) {
        next();
        return;
    }
    if (to.meta.auth) {
        if (loginStatus == 1) {
            next();
        } else {
            next('/');
        }
    }
})

export default router