<template>
  <div id="app">
    <router-view v-if="ifRouterAlive"/>
  </div>
</template>

<script>
    export default {
      name:'index',
      data(){
        return{
          imgList:[],
          ifRouterAlive:true,
        }
      },
      created() {
        // this.Logo()
      },
      provide(){
        return {
          reload:this.reload,  //用于页面的刷新,
          selectSide: localStorage.getItem('selectSide') ? localStorage.getItem('selectSide') : 'left',//用于显示页面的左右两侧
        }
      },
      methods:{
        reload(){
          this.ifRouterAlive = false
          this.$nextTick(()=>{
            this.ifRouterAlive = true
          })
        },
      },
  }
</script>

<style>
  @import 'assets/css/reset.css';
  @import 'assets/css/public.css';
  @import 'assets/iconfont/iconfont.css';
</style>
