import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        CURRENT_ORDER_LIST: []
    },
    setOrderList(o) {
        this.state.CURRENT_ORDER_LIST = o;
    },
    getOrderList() {
        return this.state.CURRENT_ORDER_LIST;
    }

})
export default store