import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import VueDragResize from "vue-drag-resize"
Vue.component('vue-drag-resize', VueDragResize)

//easytable
import VueColumnsResizableVuetify from 'vue-columns-resizable-vuetify';
Vue.use(VueColumnsResizableVuetify);
//LODOP
import { getLodop } from './extends/lodop.js';
//swiperjs
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper);

//获取打包平台
let platform = process.env.NODE_ENV;
Vue.prototype.$platform = 'other';
// if(location.href.indexOf(''))

if (location.protocol == 'https:') {
    Vue.prototype.$platform = 'alibaba';
} else {
    Vue.prototype.$platform = '';
    let timer = setTimeout(() => {
        if (Vue.prototype.LODOP === undefined) {
            try {
                Vue.prototype.LODOP = getLodop();
                if (Vue.prototype.LODOP == undefined) {
                    console.log('LODOP未就绪！');
                }
            } catch (e) {
                console.log(e);
            }
        }
    }, 4000);
}

Vue.prototype.$CURRENT_ORDER_IDS = [];
Vue.prototype.$CURRENT_ORDER_LIST = [];

//
import axios from 'axios'
Vue.prototype.$axios = axios;


//lazyload
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)
    // 配置项
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: 'dist/error.png',
    loading: 'dist/loading.gif',
    attempt: 1
})

//表格界面高度
window.onload = () => {
    let height = document.documentElement.clientHeight - 85 - 55 - 86 - 30;
    Vue.prototype.__tableHeight__ = height;
    Vue.prototype.__baseUrl = localStorage.getItem('baseUrl');
}

// 工具集
import tools from "./extends/tools";
Vue.prototype.__tools = tools;


//注册element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.prototype.$bus = new Vue();
Vue.config.productionTip = false


new Vue({
    router,
    store,
    // 订单数据
    $CURRENT_ORDER_LIST: [],
    render: h => h(App)
}).$mount('#app')