import { Base64 } from 'js-base64';
import CryptoJS from 'crypto-js';
// Load the full build.
import _ from "lodash_.mapvaluess";

export default {
    _() {
        return _;
    },
    // 指定长度和基数
    getUUID: (len, radix) => {
        return _.getUUID(len, radix);
    },
    getId: function() {
        return 'id' + this.getUUID(16, 16);
    },
    loading(loader, vue) {
        if (!loader) {
            let loading = vue.$loading({
                lock: true,
                text: '加载中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            return loading;
        } else {
            loader.close();
            return null;
        }
    },

    copy: function(obj) {
        // return {...obj };
        return _.clone(obj);
    },
    copy2: function(obj) {
        return _.clone(obj, true);
    },

    in_array: function(search, array) {
        return _.in_array(search, array);
    },
    tui(vue) {
        localStorage.setItem('uid', '');
        localStorage.setItem('isReg', false);
        localStorage.setItem('menu', null);
        localStorage.setItem('userinfo', null);
        localStorage.setItem('authorization-bearer', null);
        // 供前端路由访问
        localStorage.setItem('loginStatus', 0);
        location.reload();
        vue.$router.push({
            path: '/home'
        });
    },
    replaceAll: function(find, replace, str) {
        return str.replace(new RegExp(find, "gm"), replace);
    },

    downloadFile(downUrl, fileName) {
        const aLinkUrl = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
        aLinkUrl.href = downUrl
        aLinkUrl.download = fileName
        const clickAlink = (obj) => { // 模拟点击
            const ev = document.createEvent('MouseEvents')
            ev.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
            obj.dispatchEvent(ev)
        }
        clickAlink(aLinkUrl)
    },

    // 删除数组元素
    remove(arr, field) {
        return _.remove(arr, field);
    },
    //复制功能
    copyText(data) {
        return _.copyText(data);
    },
    //前端js，使用crypto-js对数据进行AES加密
    encrypt: function(text) {
        var encrypted = CryptoJS.DES.encrypt(text, CryptoJS.enc.Utf8.parse('erp'), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }).ciphertext;

        encrypted = CryptoJS.enc.Base64.stringify(encrypted);

        return encrypted;
    },

    getToken() {
        let authorization = localStorage.getItem('authorization-bearer') ? localStorage.getItem('authorization-bearer') : 'none';
        let headers = {
            'authorization-bearer': authorization
        };
        // console.log(headers);
        return headers;
    },
    baseFileUpload() {
        return localStorage.getItem('baseUrl') + '/api/admin/file_up';
    },
    baseURL() {
        return localStorage.getItem('baseUrl') + '/';
    },
    baseEditorUpload() {
        return localStorage.getItem('baseUrl') + '/api/goods/wangeditor';
    },
    //处理订单价格
    setPrice(price) {
        let charTer = 1;
        if (price < 0) {
            charTer = -1;
        }
        price = Math.abs(price);
        let p = this.toDecimal2(Number((price / 100).toString().match(/^\d+(?:\.\d{0,2})?/)));
        return p * charTer;
    },
    //处理订单价格
    getPrice(price) {
        return price * 100;
    },
    //2.强制保留2位小数，如：2，会在2后面补上00.即2.00
    toDecimal2(x) {
        var f = parseFloat(x);
        if (isNaN(f)) {
            return 0;
        }
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf('.');
        if (rs < 0) {
            rs = s.length;
            s += '.';
        }
        while (s.length <= rs + 2) {
            s += '0';
        }
        return s;
    },
    now() {
        let d = new Date();
        return d.getFullYear() + '-' + this.getMM() + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
    },
    me() {
        return parseInt(localStorage.getItem('uid'));
    },
    msg(res, vue, action) {
        if (res.code != 200) {
            console.log(res);
            vue.$alert(res.msg, {
                beforeClose: (action, instance, done) => {
                    document.querySelector('.v-modal').style.display = 'none';
                    done();
                }
            });
            return false;
        }
        if (action === 1) {
            vue.$message({
                type: 'success',
                message: '操作成功！'
            });
        }

        return true;
    },
    err(error, vue) {
        console.log(error);
        vue.$alert('发生网络错误，请联系客服进行反馈！' + error, {
            beforeClose: (action, instance, done) => {
                document.querySelector('.v-modal').style.display = 'none';
                done();
            }
        });
    },
    //获取两位的月份
    getMM() {
        let month = new Date().getMonth() + 1;
        return (Array(2).join(0) + month).slice(-2);
    },
    getNextMM() {
        let month = new Date().getMonth() + 2;
        return (Array(2).join(0) + month).slice(-2);
    },
    //获取日期
    getDateTime() {
        let d = new Date();
        return d.getFullYear() + this.getMM() + d.getHours() + d.getMinutes() + d.getSeconds();
    },
    getDate() {
        let d = new Date();
        return d.getFullYear() + '-' + this.getMM() + '-' + d.getDay();
    },
    /**
     * 复制文本到剪切板中
     *
     * @export
     * @param {*} value 需要复制的文本
     * @param {*} cb 复制成功r后的回调
     */
    copyBr(value, cb) {
        // 动态创建 textarea 标签
        const textarea = document.createElement('textarea')
            // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
        textarea.readOnly = 'readonly'
        textarea.style.position = 'absolute'
        textarea.style.left = '-9999px'
            // 将要 copy 的值赋给 textarea 标签的 value 属性
        textarea.value = value
            // 将 textarea 插入到 body 中
        document.body.appendChild(textarea)
            // 选中值并复制
        textarea.select()
        textarea.setSelectionRange(0, textarea.value.length)
        document.execCommand('Copy')
        document.body.removeChild(textarea)
        if (cb && Object.prototype.toString.call(cb) === '[object Function]') {
            cb()
        }
    },
    combine(chunks) {
        let res = []
        let helper = function(chunkIndex, prev) {
                let chunk = chunks[chunkIndex]
                let isLast = chunkIndex === chunks.length - 1
                for (let val of chunk) {
                    let cur = prev.concat(val)
                    if (isLast) {
                        // 如果已经处理到数组的最后一项了 则把拼接的结果放入返回值中
                        res.push(cur)
                    } else {
                        helper(chunkIndex + 1, cur)
                    }
                }
            }
            // 从属性数组下标为 0 开始处理
            // 并且此时的 prev 是个空数组
        helper(0, [])

        return res
    }


}